import React, { Fragment, useEffect, useState } from 'react';

import {
    Grid,
    Container,
    Input,
    InputLabel,
    InputAdornment,
    Card,
    CardContent,
    Button,
    FormControl
} from '@material-ui/core';

import LockTwoToneIcon from '@material-ui/icons/LockTwoTone';
import PersonIcon from '@material-ui/icons/Person';
import svgImage9 from '../../../assets/images/illustrations/login.svg';
import axios from "axios";
import { useCookies } from 'react-cookie';


const PageLogin = () => {

    const [username, setUserName] = useState('')
    const [password, setPassword] = useState('')
    const [token, setToken] = useCookies();
    const [logUserName, setLogUserName] = useCookies();

    useEffect(() => {
        console.log('Login page token: ' + token);
        if (token['mr-token']) window.location.href = '/'
    }, [token]);


    const loginClicked = () => {
        axios.post('https://apilms.rapidnet.cz/auth/', { username: username, password: password })
            .then(result => {
                setToken('mr-token', result.data.token);
                setLogUserName('mr-username', username);
            })
            .catch(error => console.log(error))
    }

    return (
        <Fragment>
            <div className="app-wrapper min-vh-100">
                <div className="app-main flex-column">
                    <div className="app-content p-0">
                        <div className="app-content--inner d-flex align-items-center">
                            <div className="flex-grow-1 w-100 d-flex align-items-center">
                                <div className="bg-composed-wrapper--content py-5">
                                    <Container maxWidth="lg">
                                        <Grid container spacing={5}>
                                            <Grid
                                                item
                                                xs={12}
                                                lg={5}
                                                className="d-none d-xl-flex align-items-center">
                                                <img
                                                    alt="..."
                                                    className="w-100 mx-auto d-block img-fluid"
                                                    src={svgImage9}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                lg={7}
                                                className="d-flex flex-column align-items-center">
                                                <span className="w-100 text-left text-md-center pb-4">
                                                    <h1 className="display-3 text-xl-left text-center mb-3 font-weight-bold">
                                                        Musíte se přihlásit, jinak to nejde ...
                                                    </h1>
                                                </span>
                                                <Card className="m-0 w-100 p-0 border-0">
                                                    <div
                                                        className="card-header d-block p-3 mx-2 mb-0 mt-2 rounded border-0">
                                                        <div className="text-center">
                                                        </div>
                                                    </div>
                                                    <CardContent className="p-3">
                                                        <form className="px-5">
                                                            <div className="mb-3">
                                                                <FormControl className="w-100">
                                                                    <InputLabel htmlFor="input-with-icon-adornment">
                                                                        Jméno
                                                                    </InputLabel>
                                                                    <Input
                                                                        fullWidth
                                                                        id="input-with-icon-adornment"
                                                                        onChange={evt => setUserName(evt.target.value)}
                                                                        startAdornment={
                                                                            <InputAdornment position="start">
                                                                                <PersonIcon />
                                                                            </InputAdornment>
                                                                        }
                                                                    />
                                                                </FormControl>
                                                            </div>
                                                            <div className="mb-3">
                                                                <FormControl className="w-100">
                                                                    <InputLabel htmlFor="standard-adornment-password">
                                                                        Heslo
                                                                    </InputLabel>
                                                                    <Input
                                                                        id="standard-adornment-password"
                                                                        fullWidth
                                                                        type="password"
                                                                        onChange={evt => setPassword(evt.target.value)}
                                                                        startAdornment={
                                                                            <InputAdornment position="start">
                                                                                <LockTwoToneIcon />
                                                                            </InputAdornment>
                                                                        }
                                                                    />
                                                                </FormControl>
                                                            </div>
                                                            <div className="text-center">
                                                                <Button
                                                                    color="primary"
                                                                    variant="contained"
                                                                    size="large"
                                                                    className="my-2"
                                                                    onClick={loginClicked}
                                                                >
                                                                    Přihlásit
                                                                </Button>
                                                            </div>
                                                        </form>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        </Grid>
                                    </Container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};
export default PageLogin;
