import {createMuiTheme} from '@material-ui/core';
import typography from './themeTypography';
import * as themeColors from './themeColors'

const MuiTheme = createMuiTheme({
    palette: {
        common: {
            grey: {
                main: themeColors.grey,
            },
            orange: {
                main: themeColors.orange,
            }

        },
        primary: {
            main: themeColors.primary,
            light: themeColors.primaryLight,
            dark: themeColors.primaryDark
        },
        secondary: {
            main: themeColors.secondary
        },
        red: {
            main: themeColors.red
        },
        purple: {
            main: themeColors.purple
        },
        pink: {
            main: themeColors.pink
        },
        teal: {
            main: themeColors.teal
        },
        yellow: {
            main: themeColors.yellow
        },
        cyan: {
            main: themeColors.cyan
        },
        indigo: {
            main: themeColors.indigo
        },
        error: {
            main: themeColors.red
        },
        success: {
            main: themeColors.green
        },
        warning: {
            main: themeColors.orange
        },
        helpers: {
            primary: themeColors.primary,
            main: 'rgba(25, 46, 91, .035)'
        },
        contrastThreshold: 3,
        tonalOffset: 0.1
    },
    shape: {
        borderRadius: '0.5rem'
    },
    overrides: {
        MuiFormControlLabel: {
            label: {
                color: themeColors.primary,
                fontWeight: 700
            },
            labelPlacementStart: {
                marginLeft: 0
            }
        },
        MuiButton: {
            text: {
                paddingLeft: '14px',
                paddingRight: '14px'
            },
            containedSizeSmall: {
                paddingLeft: '14px',
                paddingRight: '14px'
            },
            root: {
                textTransform: 'none',
                fontWeight: 500
            }
        },
        MuiTooltip: {
            tooltip: {
                backgroundColor: themeColors.second,
                padding: '8px 16px',
                fontSize: '13px'
            },
            arrow: {
                color: themeColors.second
            }
        },
        MuiSvgIcon: {
          root: {
              "&.MuiSelect-icon": {
                  fill: themeColors.orange
              }
          }
        },
        // MuiTableCell: {
        //     head: {
        //         fontSize: ".9rem",
        //         fontWeight: 700,
        //         color: themeColors.secondary,
        //         background: themeColors.primaryLight,
        //         borderCollapse: themeColors.secondary
        //     }
        // }
    },
    typography
});

export default MuiTheme;
