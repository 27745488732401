import React, {lazy, Suspense, Fragment} from 'react';
import {Switch, Route, Redirect, useLocation} from 'react-router-dom';
import {AnimatePresence, motion} from 'framer-motion';
import {ThemeProvider} from '@material-ui/styles';
import {ClimbingBoxLoader} from 'react-spinners';
import MuiTheme from './theme';
import { CookiesProvider } from 'react-cookie';

// Layout Blueprints
import {LeftSidebar} from './layout-blueprints';

const Balance = lazy(() => import('./pages/LmsCashInfo/Balance'));
const LmsCustomerInfo = lazy(() => import('./pages/Customers/Customers'));
const Dashboard = lazy(() => import('./pages/Dasboard'));
const Login = lazy(() => import('./pages/PagesLogin'));
const RouterList = lazy(() => import('./pages/RoutersBackup/RouterList'));
const TariffsAssigments = lazy(() => import('./pages/CustomersTariffs/CustomersTarrifs'))
const IpHistory = lazy(() => import('./pages/IpHistory/IpHistoryList'))

const Routes = () => {
    const location = useLocation();
    // const [token] = useCookies(['mr-token']);

    const pageVariants = {
        initial: {
            opacity: 0,
            scale: 0.99
        },
        in: {
            opacity: 1,
            scale: 1
        },
        out: {
            opacity: 0,
            scale: 1.01
        }
    };

    const pageTransition = {
        type: 'tween',
        ease: 'anticipate',
        duration: 0.4
    };

    const SuspenseLoading = () => {
        return (
            <Fragment>
                <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
                    <div className="d-flex align-items-center flex-column px-4">
                        <ClimbingBoxLoader color={'#5383ff'} loading={true}/>
                    </div>
                    <div className="text-muted font-size-xl text-center pt-3">
                        Nahrávám aplikaci ....
                        <span className="font-size-lg d-block text-dark">
              This live preview instance can be slower than a real production
              build!
            </span>
                    </div>
                </div>
            </Fragment>
        );
    };
    return (
        <ThemeProvider theme={MuiTheme}>
            <AnimatePresence>
                <Suspense fallback={<SuspenseLoading/>}>
                    <CookiesProvider>
                        <Switch>
                            {/*<Route exact path={'/'} component={LoginPage} />*/}
                            <Redirect exact from="/" to="/Dashboard"/>
                            <Route
                                path={[
                                    '/Dashboard',
                                    '/Balance',
                                    '/Customers',
                                    '/RoutersBackup',
                                    '/TariffAssigments',
                                    '/IpHistory',
                                ]}>
                                <LeftSidebar>
                                    <Switch location={location} key={location.pathname}>
                                        <motion.div
                                            initial="initial"
                                            animate="in"
                                            exit="out"
                                            variants={pageVariants}
                                            transition={pageTransition}>
                                            <Route
                                                path="/Dashboard"
                                                component={Dashboard}
                                            />
                                            <Route
                                                path="/Balance"
                                                component={Balance}
                                            />
                                            <Route
                                                path="/Customers"
                                                component={LmsCustomerInfo}
                                            />
                                            <Route
                                                path="/RoutersBackup"
                                                component={RouterList}
                                            />
                                            <Route
                                                path="/TariffAssigments"
                                                component={TariffsAssigments}
                                            />
                                            <Route
                                                path="/IpHistory"
                                                component={IpHistory}
                                            />
                                        </motion.div>
                                    </Switch>
                                </LeftSidebar>
                            </Route>
                            <Route exact from='/Login' component={Login} />
                        </Switch>
                    </CookiesProvider>
                </Suspense>
            </AnimatePresence>
        </ThemeProvider>
    );
};

export default Routes;
