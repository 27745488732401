import React, {Fragment} from 'react';

import clsx from 'clsx';

import {
    Avatar,
    Box,
    Button,
} from '@material-ui/core';

import avatar2 from '../../assets/images/avatars/avatar2.jpg';
import {connect} from 'react-redux';
import {useCookies} from 'react-cookie';

import {makeStyles} from "@material-ui/styles";
import {themeSpacer} from "../../theme/themeConst";

const useStyles = makeStyles(theme => ({
    userBox: {
        background: theme.palette.common.grey - 200,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: themeSpacer,
        margin: 0,
        borderRadius: 0,
        textAlign: 'center',
        whiteSpace: 'nowrap'
    },
    userBoxAvatar: {
        width: '54px',
        height: '54px',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: themeSpacer / 2

    },
    userBoxName: {
        opacity: 1,
        height: 'auto',
        whiteSpace: 'nowrap'

    },
    userBoxDescription: {
        opacity: '.7',
        marginBottom: themeSpacer / 2
    },
    userBoxCollapsed: {
        paddingLeft: themeSpacer,
        paddingRight: themeSpacer,
        background: 'transparent'
    },
    logoutBox: {
        padding: '1em',
        right: themeSpacer,
        top: themeSpacer
    },
    logoutButton: {
        color: 'white'
    }

}));

const SidebarUserbox = props => {
    const classes = useStyles();
    const {sidebarToggle, sidebarHover} = props;
    const [token, setToken, removeToken] = useCookies(['mr-token']);
    const [userName, setUserName, removeUserName] = useCookies(['mr-username']);


    const logoutUser = () => {
        removeToken(['mr-token']);
        removeUserName(['mr-username']);
        window.location.href = '/Login';
    }

    return (
        <Fragment>
            <Box
                className={
                    clsx([classes.userBox], {
                        [classes.userBoxCollapsed]: sidebarToggle && !sidebarHover
                    })}
            >
                <Avatar
                    alt="Remy Sharp"
                    src={avatar2}
                    className={classes.userBoxAvatar}
                />
                <Box className={classes.userBoxName}>
                    <Box>
                        <b>{userName['mr-username']}</b>
                    </Box>
                    <Box className={classes.userBoxDescription}>
                        Senior Web Developer
                    </Box>
                    <Box className={classes.logoutBox}>
                        <Button
                            size="small"
                            color="secondary"
                            variant="contained"
                            className={classes.logoutButton}
                            onClick={logoutUser}>
                            Logout
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Fragment>
    );
};

const mapStateToProps = state => ({
    sidebarToggle: state.ThemeOptions.sidebarToggle,
    sidebarHover: state.ThemeOptions.sidebarHover
});

export default connect(mapStateToProps)(SidebarUserbox);
