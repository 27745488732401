import React, {Fragment} from 'react';

import clsx from 'clsx';

import { Paper } from '@material-ui/core';

import {connect} from 'react-redux';

const Footer = props => {
    const {footerShadow, sidebarToggle, footerFixed} = props;
    return (
        <Fragment>
            <Paper
                square
                elevation={footerShadow ? 11 : 2}
                className={clsx('app-footer text-black-50', {
                    'app-footer--fixed': footerFixed,
                    'app-footer--fixed__collapsed': sidebarToggle
                })}>
                <div className="app-footer--inner">
                    <div className="app-footer--first">
                    </div>
                    <div className="app-footer--second">
                        <span>ORKA Systems s.r.o.</span> ©
                        2020 - crafted with <span className="text-danger px-1">❤</span> by{' '}
                        <a href="https://orkasolutions.cz" title="ORKA solutions.cz">
                            OrkaSolutions.cz
                        </a>
                    </div>
                </div>
            </Paper>
        </Fragment>
    );
};

const mapStateToProps = state => ({
    footerFixed: state.ThemeOptions.footerFixed,
    footerShadow: state.ThemeOptions.footerShadow,
    sidebarToggle: state.ThemeOptions.sidebarToggle
});
export default connect(mapStateToProps)(Footer);
