// Barvy
export const primary = '#0B72B9';
export const primaryLight = '#495368';
export const primaryDark = '#09142a';
export const secondary = '#3d4977';

export const second = '#070919';
export const indigo = '#7420ff';
export const purple = '#793de6';
export const pink = '#fc26a4';
export const red = '#f83245';
export const orange = '#FFBA60';
export const yellow = '#ffc926';
export const green = '#1bc943';
export const teal = '#18e1a5';
export const cyan = '#27dcf3';
export const grey = '#868686';
