import {primary} from './themeColors';

export default {
    h1: {
        fontWeight: 500,
        fontSize: '35px',
        lineHeight: '40px',
        color: primary
    },
    h2: {
        fontFamily: "Roboto",
        fontWeight: 300,
        fontSize: "40px",
        color: "silver",
        lineHeight: 1.5

    },
    h3: {
        fontWeight: 500,
        fontSize: '24px',
        lineHeight: '28px'
    },
    h4: {
        fontWeight: 500,
        fontSize: '20px',
        lineHeight: '24px',
        color: primary
    },
    h5: {
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '20px'
    },
    h6: {
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '20px'
    },
    subtitle1: {
        fontSize: '14px',
        lineHeight: '25px'
    },
    subtitle2: {
        fontWeight: 400,
        fontSize: '12px'
    },
    body1: {
        fontSize: '14px'
    },
    body2: {
        fontSize: '12px'
    },
    button: {
        fontSize: '12px'
    },
    caption: {
        fontSize: '12px'
    },
    overline: {
        fontSize: '12px',
        fontWeight: 500,
        textTransform: 'uppercase'
    },
    tab: {
        fontFamily: "Roboto",
        textTransform: 'none',
        fontSize: '0.75rem',
        fontWeight: 700
    }

}
